<div class="ibk-step" (click)="onClick()">
  <div class="ibk-step__number"
    [ngClass]="{'checked': checked, 'active': active}">
    <span>{{ stepNumber }}</span>
    <img
      class="ibk-step__check"
      src="/assets/img/check-outline.svg"
      alt="img"
      *ngIf="checked"
    />
  </div>
  <span class="ibk-step__text">{{ description }}</span>
</div>
