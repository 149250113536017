export enum ParametersEnum {
    currency = 7,
    attributeType = 8,
    inputAttribute = 10,
    campaignState = 12,
    campaignTypes = 13,
    campaignStateValidate = 14,
    campaignProducts = 20,
    enoteTypes = 21,
    reason = 22, //motivo
    banks = 24,
    campaignProductsTypes = 25,
    categoryInstructive = 30,
    renewal = 31,
    contactType = 32,
    userAccessView = 35,
    escom = 40
}
