import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly securityService: SecurityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const paths = this.securityService.getMenuPath();
    console.log(paths)
    console.log(state.url?.trim());
    const exist = paths.some((item) =>
      state.url?.trim()?.includes(item.path?.trim())
    );
    
    if ((state.url?.trim() == '/dashboard' && paths.length == 0) || (state.url?.trim() != '/dashboard' && !exist)) {
      this.router.navigate(['/sin-acceso']);
      return false;
    }

    return true;
  }
}
