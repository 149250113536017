import { AbstractControl } from "@angular/forms";

export const minLengthArray = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    const exist = control.value.some((x: any) => x.checked);
      if (exist) {
        return null;
      } else {
        return { minLengthArray: true };
      }
  };