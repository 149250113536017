export const environment = {
    production: false,
    mockApi:false,
    base_url: 'https://api.dev.interbank.per',
    uat: false,
    security_path: '/vpc-security/v1/api/reverse-proxy/security-api/security',
    leads_path: '/vpc-leads/v1/api/reverse-proxy/leads-api/leads',
    config_leads_path: '/vpc-config-leads/v1/api/reverse-proxy/config-leads-api/config-leads',
    contact_path: '/vpc-contact/v1/api/reverse-proxy/contact-api/contact',
    reporting_path: '/vpc-reporting/v1/api/reverse-proxy/reporting-api/reporting',
    users_path: '/vpc-users/v1/api/reverse-proxy/users-api/users',
    commons_path: '/vpc-commons/v1/api/reverse-proxy/commons-api/commons',
    closeness_path: '/vpc-closeness/v1/api/reverse-proxy/closeness-api/closeness',
    credit_document_path: '/vpc-credit-document/v1/api/reverse-proxy/credit-document-manager',
    access_path: '/vpc-access/v1/api/reverse-proxy/access-api/access',
    azure: {
      clientId: '65abfacb-6c0f-4bd7-97e3-3f51accb583c',
      tenantId: '360bc517-7aac-4c17-9907-4c5f13a12289'
    },
    ocpApimSubscriptionKey: '52a4fc1859114e8386e02d135e15f8d9',
    authAD: {
      clientId: 'vpc-client',
      clientSecret: '1nterb4nk-secret',
    }
};
