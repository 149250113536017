import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MenuListModel } from 'src/app/models';
import { TypesMenuListEnum } from 'src/app/shared/core/enums/type.menu.list.enum';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit, OnChanges {

  @Input() buttonText: string = 'Buscar';
  // @Input() labelText: string = 'description';
  /**
   * the value must be an array
   */
  @Input() data: MenuListModel[];
  @Input() width: number = 147;
  @Input() marginTop: number = 11;
  @Input() type: TypesMenuListEnum = TypesMenuListEnum.IMAGE;
  @Input() image: string = '/assets/img/arrow/arrow-down-3.svg';
  @Input() labelText: string = 'Ingrese texto';
  @Input() multiple: boolean = true;
  @Output() sendSelectedValue = new EventEmitter();
  @Output() sendValues = new EventEmitter();
  
  formArray: FormArray = new FormArray([]);
  selectedIndex: number | null;

  constructor() { }

  public get TypesMenuListEnum(): typeof TypesMenuListEnum {
    return TypesMenuListEnum;
  }
  
  get selectText() {
    const length = this.formArray?.getRawValue()?.length || 0;
    if (length == 0) return 'No hay datos';

    const values = this.formArray.getRawValue().filter(item => item.checked);

    if (values.length == 0) return 'Seleccione';

    return values.length == length ? 'Todos' : values.length > 1 ? values[0].description + ' (+ otros)' : values[0].description; 
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      const values: MenuListModel[] = changes["data"]?.currentValue || [];
      const length = this.formArray?.controls?.length || 0;
      if (length == 0 && values?.length > 0) {
        for (const item of values) {
          const form = this.createForm(item);
          this.formArray.push(form);
        }
      }
    }
  }

  createForm(item: MenuListModel) {
    return new FormGroup({
      checked: new FormControl(item.checked),
      data: new FormControl(item.data),
      description: new FormControl(item.description),
      value: new FormControl(item.value)
    });
  }

  setAll(checked: boolean) {
    this.formArray.controls?.forEach(item => {
      item.get('checked')?.setValue(checked);
    });
  }

  allComplete() {
    const total = this.formArray?.getRawValue()?.length || 0;
    const list = this.formArray?.getRawValue() || [];
    if (list.length > 0 && total > 0) {
      const selectedCount = list.filter((x: any) => x.checked)?.length;
      return selectedCount == total;
    }
    return false;
  }

  someComplete() {
    const total = this.formArray?.getRawValue()?.length || 0;
    const list = this.formArray?.getRawValue() || [];
    if (list.length > 0 && total > 1) {
      const selectedCount = list.filter((x: any) => x.checked)?.length;
      return selectedCount > 0 && total > selectedCount;
    }
    return false;
  }

  changeValue(form: FormGroup) {
    this.sendSelectedValue.emit(form.value);
  }

  send() {
    const formValues = this.formArray?.getRawValue() || [];
    if (formValues.length > 0) {
      // const values = formValues.filter((item: any) => item.checked);
      const data = formValues.map(item => new MenuListModel(item.checked, item.value, item.description, item.data));
      this.sendValues.emit(data);
    }
  }

  selectItem(index: number | null, item?: MenuListModel) {
    this.selectedIndex = index;
    this.sendSelectedValue.emit(item ?? null);
  }

}
