<div class="table-container"
    [ngStyle]="{'height': tableHeight }">
    <div class="table-container__header"
        [ngStyle]="{'min-width': minWidth}">
        <div *ngFor="let header of headers; let i = index" class="table-header-cell"
            [ngStyle]="{'width': header.width}">
            <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: header}">
            </ng-container>
        </div>
    </div>
    <div class="table-container__body"
        [ngStyle]="{'min-width': minWidth}">
        <div *ngFor="let row of data" class="table-row">
            <div *ngFor="let header of headers;" class="table-cell"
                [ngStyle]="{'width': header.width}">
                <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: getName(row, header) }">
                </ng-container>
            </div>
            <!-- <div *ngFor="let cell of row; let i = index" class="table-cell"
                [ngStyle]="{'flex': columnWidths[i] || '1'}">
                <ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: cell}">
                </ng-container>
            </div> -->
        </div>
    </div>
</div>