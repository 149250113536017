import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CampaignStepOneRequest,
  CampaignStepThreeRequest,
  CampaignStepTwoRequest,
} from '../pages/campaign-config/pages/campaign/models/campaign.model';
import { PathRest } from '../static/path.rest';
import {
  ContentType,
  HttpClientService,
} from '../shared/core/services/http-client.service';
import { catchError, map } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignSettingsService {

  private urlConfigLeads = `${environment.base_url}${environment.config_leads_path}`;
  // private urlConfigLeads = `http://localhost:8081${environment.config_leads_path}`;

  constructor(private http: HttpClient,
    private readonly httpNew: HttpClientService,
    private readonly authService: AuthService) {}

  getAttributes() {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: localStorage.getItem('userinfo') || '',
    };
    const path = `${this.urlConfigLeads}/campaign-format/detail-catalog`;
    return this.http.post<any>(
      path,
      { idCampaignFormart: 1 },
      { headers }
    );
  }

  /*registerCampaignStepOne(body: CampaignStepOneRequest, action: string) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: localStorage.getItem('userinfo') || '',
    };
    const endpoint =
      action == 'edit'
        ? PathRest.POST_UPDATE_CAMPAIGN_ATTRIBUTE
        : PathRest.POST_REGISTER_CAMPAIGN_ATTRIBUTE;
    return this.http.post<any>(endpoint, body, { headers });
  }*/

  registerCampaignStepOne(body: CampaignStepOneRequest, action: string) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.httpNew.getContentType(ContentType.JSON),
      }
    }

    let path = `${this.urlConfigLeads}`;
    body.modifiedBy=this.authService.user;
    action == 'edit'
      ? path = path + '/campaign/update-campaign-general-attribute'
      : path = path + '/campaign/insert-campaign-general-attribute'
    return this.httpNew.post<any>(path, body, options)
  }

  registerCampaignStepTwo(body: CampaignStepTwoRequest, action: string) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.httpNew.getContentType(ContentType.JSON),
      }
    }
    let path = `${this.urlConfigLeads}`;
    body.modifiedBy=this.authService.user;
    action == 'edit'
      ? path = path + '/campaign/update-campaign-state-substate'
      : path = path + '/campaign/insert-campaign-state-substate'
    return this.httpNew.post<any>(path, body, options)
  }

  /*registerCampaignStepThree(body: CampaignStepThreeRequest, action: string) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: localStorage.getItem('userinfo') || '',
    };
    const endpoint =
      action == 'edit'
        ? PathRest.POST_UPDATE_CAMPAIGN_PRODUCTS
        : PathRest.POST_REGISTER_CAMPAIGN_PRODUCTS;
    return this.http.post<any>(endpoint, body, { headers });
  }*/

  registerCampaignStepThree(body: CampaignStepThreeRequest, action: string) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.httpNew.getContentType(ContentType.JSON),
      }
    }
    let path = `${this.urlConfigLeads}`;
    body.modifiedBy=this.authService.user;
    action == 'edit'
      ? path = path + '/campaign/update-campaign-product-subproduct'
      : path = path + '/campaign/insert-campaign-product-subproduct'
    return this.httpNew.post<any>(path, body, options)
  }

  /*saveOfferValueImage(body: any) {
    const headers = {
      // 'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = PathRest.PROCESS_OFFER_VALUE_IMAGE;
    return this.http.post<any>(path, body, { headers });
  }*/

  saveOfferValueImage(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    let path = `${this.urlConfigLeads}/offer-value/process-offer-value-ima`;
    return this.httpNew.post<any>(path, body, options)
  }

  deleteOffetValue(idOfferValue: number) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
      }
    }
    let path = `${this.urlConfigLeads}/offer-value/delete-offer-value`;
    return this.httpNew.post<any>(path, idOfferValue, options)
  }

  postProcessProductDocument(body: any) {
    const options = {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
        'Content-Type': this.httpNew.getContentType(ContentType.JSON),
      }
    }
    let path = `${this.urlConfigLeads}/product-document/process-product-document`;
    return this.httpNew.post<any>(path, body, options)
  }


  searchCampaign(id: number) {
    const request = {
      typeElement: 0,
      idElement: id,
    };
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: localStorage.getItem('userinfo') || '',
    };
    const path = `${this.urlConfigLeads}/campaign/search-campaign`;
    return this.http.post<any>(path, request, {
      headers,
    });
  }

  searchCampaignAttribute(id: number, typeElement: number) {
    const user = localStorage.getItem('userinfo') || '';
    const request = {
      typeElement: typeElement,
      idElement: id,
      userRegistration: typeElement == 1 ? user : undefined
    };
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: user,
    };
    const path = `${this.urlConfigLeads}/campaign/search-campaign-lead-attribute`;
    return this.http.post<any>(
      path,
      request,
      { headers }
    );
  }

  searchCampaignElement(id: number, typeElement: number) {
    const request = {
      typeElement: typeElement,
      idElement: id,
    };
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      userRegistration: localStorage.getItem('userinfo') || '',
    };
    const path = `${this.urlConfigLeads}/campaign/search-campaign-lead-element`;
    return this.http.post<any>(path, request, {
      headers,
    });
  }

  getCampaigns(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = `${this.urlConfigLeads}/campaign/list-campaign`;
    return this.http.post<any>(path, body, { headers });
  }

  getValueOffer(campaignId: number) {
    const body = {
      campaignInstance: campaignId,
    };
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = `${this.urlConfigLeads}/offer-value/search-offer-value`;
    return this.http.post<any>(path, body, { headers });
  }

  getProductsWithFile(campaignId: number) {
    const body = {
      idCampaignInstance: campaignId,
    };
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = `${this.urlConfigLeads}/productFile/listProductWithFile`;
    return this.http.post<any>(path, body, { headers });
  }

  getProductFolderFile(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = `${this.urlConfigLeads}/productFile/listProductFolderFile`;
    return this.http.post<any>(path, body, { headers });
  }

  getSummary(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
    };
    const path = `${this.urlConfigLeads}/campaign/summary-campaign`;
    return this.http.post<any>(path, body, { headers });
  }

}
