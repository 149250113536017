import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() headers: any = [];
  @Input() data: any = [];
  @Input() headerTemplate: any;
  @Input() bodyTemplate: any;
  @Input() tableHeight: string = 'auto';
  @Input() minWidth: string = '1100px';

  constructor() { }

  ngOnInit(): void {
  }

  getName(row: any, header: any) {
    const arr = header.name?.split('.') || [];
    return arr.length > 1 ? {column: header.name, value: this.getData(row, arr)} : {column: header.name, value: row[header.name]};
  }

  getData(row: any, columnNames: string[]) {
    for (const item of columnNames) {
      row = row[item]
    }
    return row;
  }

}
