import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { NopagefoundComponent } from './pages/nopagefound/nopagefound.component';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { HeaderComponent } from './shared/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { PagesComponent } from './pages/pages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { materialModule } from 'src/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './components/modal/modal.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor/interceptor.service';
import { LeadService } from './services/lead.service';
import { AuthGuard } from './guards/auth.guard';
import { FormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_DATE_FORMATS } from '../assets/ts/datepickerConf';
import { DatePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { STORE_MODULES } from './store';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';

import { ContactsModule } from './pages/contacts/contacts.module';
import { CampaignService } from './pages/campaign-config/pages/campaign/services/campaign.service';
import { CampaignConfigComponent } from './pages/campaign-config/campaign-config.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { AppAzureModule } from './app-azure.modules';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { BackOfficeComponent } from './auth/backoffice/backoffice.component';
import { ComponentsModule } from './components/components.module';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { AccessGuard } from './shared/guards/access.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NopagefoundComponent,
    DashboardComponent,
    SidebarComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    PagesComponent,
    ModalComponent,
    PortfolioComponent,
    CampaignConfigComponent,
    PrincipalComponent,
    BackOfficeComponent,
    NoAccessComponent
  ],
  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    materialModule,
    HttpClientModule,
    MatTabsModule,
    FormsModule,
    OrderModule,
    STORE_MODULES,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ContactsModule,
    MatCheckboxModule,
    MatExpansionModule,
    AppAzureModule,
    PowerBIEmbedModule,
    ComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

    LeadService,
    AuthGuard,
    DatePipe,
    CampaignService,
    AccessGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
