import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../../services/message.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ContactService } from '../../service/contact.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-detail-contact',
  templateUrl: './detail-contact.component.html',
  styleUrls: ['./detail-contact.component.scss']
})
export class DetailContactComponent implements OnInit {

  public idContact:any;
  public search:any;
  public enterprise:any = {};
  public contactSelect:any = {};

  get user(): string {
    return localStorage.getItem('userinfo') || '';
  }

  constructor(private _Activatedroute:ActivatedRoute,
              private messageService: MessageService,
              public loaderService:LoaderService,
              private contactService: ContactService,
              private router: Router){

    this._Activatedroute.paramMap.subscribe(params => {
      this.idContact = params.get('idContactSelect');
      this.search = params.get('ruc');
    });
  }

  ngOnInit(): void {
    this.getContactsByEnterprises();
  }

  public getContactsByEnterprises(){
    let promise = new Promise((resolve, reject) => {
      let body = {
        "search": this.search,
        "userRegistration": this.user
      }
      let bodyC = {
        "documentNumber": "20100188202",
        "type": 2
      }

      this.contactService.getContactsByEnterprises(body).subscribe(
        enterpriseList => {
          if(enterpriseList.mensaje!=null){
            this.messageService.showWarning(enterpriseList.mensaje, 'top right');
            return;
          }

          if(enterpriseList.length>0){
            this.enterprise = enterpriseList[0];
            bodyC.documentNumber = this.enterprise.ruc;
            bodyC.type = this.enterprise.type;

            this.contactService.getListContactsByEnterprises(bodyC).subscribe(
              contactList => {
                this.enterprise.lisContacts = contactList;
                if (contactList.length > 0) {
                  if (this.enterprise.lisContacts.length > 0) {
                    let i = 0;
                    let selectValidate = 0;

                    this.enterprise.listContactsView = [];

                    this.enterprise.lisContacts.forEach((contact: any) => {
                      if (i < 12 || selectValidate == 0) {
                        this.enterprise.listContactsView.push(contact);
                      }
                      if (contact.idContact == this.idContact) {
                        this.contactSelect = contact;

                        if (this.contactSelect.productRole != null && this.contactSelect.productRole != "") {
                          this.contactSelect.productRoleList = this.contactSelect.productRole.split(',');
                        }
                        selectValidate = 1;
                      }
                      i++;
                    });
                  }
                  resolve(1);
                }
              });
          }
        },
        error => {
          if(error.error.mensaje){
            this.messageService.showError(error.error.mensaje, 'top right');
          }
          else{
            this.messageService.showError(error.statusText, 'top right');
          }

        }
      )
    });
    return promise;
  }

  goToListContacts(){
    let url= '/dashboard/contacts/contact-list'
    this.router.navigateByUrl(url)
  }

  goToListContactsDualView(){
    let url= '/dashboard/contacts/contact-dual-view'
    this.router.navigateByUrl(url)
  }

}
