import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';
import { AccessModuleEnum } from '../../core/enums/access.module.enum';

@Directive({
  selector: '[hasPermission]'
})
export class PermissionsDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly securityService: SecurityService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.updateView();
  }

  async updateView() {
    let hasPermissions: boolean;

    try {
      // hasPermissions = this.checkPermissions();
      hasPermissions = true;
    } catch (error) {
      hasPermissions = false;
    }

    if (hasPermissions) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  checkPermissions() {
    const paths = this.securityService.getMenuPath();
    const sub = paths.find(item => this.router.url?.trim()?.includes(item.path?.trim()));
    return sub?.flgOption == AccessModuleEnum.EDIT;
  }
  
}
