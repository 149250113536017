import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpClientInterceptorService } from './interceptors/http-client.interceptor';
import { HttpClientService } from './services/http-client.service';
import { FileService } from './services/file.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from 'src/app/interceptor/interceptor.service';
import { DialogService } from './services/dialog.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    //   {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: HttpClientInterceptorService,
    //     multi: true
    //  },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true
    // },
    HttpClientService,
    FileService,
    DialogService
  ],
})
export class CoreModule {}
