<div class="login">
  <div class="login__logo">
    <div class="login__logo-content">
      <div class="login__logo-image">
        <img
          class="logo-mobile"
          src="/assets/img/login/logo_mobile.svg"
          alt="logo"
        />
        <img
          class="img-mobile"
          src="/assets/img/login/login_mobile.svg"
          alt="logo"
        />
      </div>
    </div>
  </div>
  <div class="login__form">
    <div class="login__form-container">
      <form [formGroup]="loginForm" (ngSubmit)="sendData()">
        <mat-error
          class="input-error"
          *ngIf="
            loginForm.controls['username'].invalid &&
            (loginForm.controls['username'].dirty ||
              loginForm.controls['username'].touched) &&
            formSubmitted
          "
        >
          <mat-icon>error_outline</mat-icon>
          <p>El campo de Usuario es inválido</p>
        </mat-error>
        <mat-error
          class="input-error"
          *ngIf="
            loginForm.controls['password'].invalid &&
            (loginForm.controls['password'].dirty ||
              loginForm.controls['password'].touched) &&
            formSubmitted
          "
        >
          <mat-icon>error_outline</mat-icon>
          <p>El campo de Contraseña es inválido</p>
        </mat-error>
        <h2 class="login__form-title">
          Ingresa <br />
          tu cuenta
        </h2>
        <h2 class="login__form-title-mobile">Bienvenido</h2>
        <div class="login__form-input">
          <mat-form-field appearance="outline">
            <mat-label>Usuario</mat-label>
            <input
              matInput
              formControlName="username"
              oninput="this.value = this.value.toUpperCase()"
              required
              autofocus
              name="username"
            />
          </mat-form-field>
        </div>
        <div class="login__form-input">
          <mat-form-field appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input
              matInput
              formControlName="password"
              [type]="hide ? 'password' : 'text'"
              required
              name="password"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div></div>
        <button
          class="login__form-button"
          mat-raised-button
          type="submit"
          color="primary"
        >
          Ingresar
        </button>
      </form>
    </div>
  </div>
</div>
