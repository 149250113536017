import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { materialModule } from 'src/material';
import { InformationModalComponent } from './components/information-modal/information-modal.component';
import { OperationFilterPipe } from './pipes/operation-filter.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { EmptyComponent } from './components/empty/empty.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { VerticalModalComponent } from './components/vertical-modal/vertical-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipUiComponent } from './components/tooltip-ui/tooltip-ui.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepComponent } from './components/step/step.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionsDirective } from './directives/permissions.directive';
import { TooltipTextComponent } from './components/tooltip-text/tooltip-text.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { TableComponent } from './components/table/table.component';

const COMPONENTS = [
  ConfirmationComponent,
  InformationModalComponent,
  EmptyComponent,
  LoadingComponent,
  TooltipComponent,
  VerticalModalComponent,
  ExpansionPanelComponent,
  TooltipUiComponent,
  ProgressBarComponent,
  StepperComponent,
  StepComponent,
  TooltipTextComponent,
  MenuListComponent,
  TableComponent
];

const PIPES = [
  OperationFilterPipe,
  CapitalizePipe
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    PermissionsDirective
  ],
  imports: [
    CommonModule,
    materialModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    materialModule,
    ...COMPONENTS,
    ...PIPES,
    PermissionsDirective
  ],
  entryComponents: [
    ConfirmationComponent,
    InformationModalComponent
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {
        close: (dialogResult: any) => { }
      }
    }
  ]
})
export class UiModule { }
