import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PathRest } from '../static/path.rest';
import { HierarchyModel } from '../models/hierarchy.model';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService {

  // private urlLeads = `${environment.base_url}${environment.leads_path}`;
  // private urlLeads = `http://localhost:8080${environment.leads_path}`;
  // private urlConfigLeads = `${environment.base_url}${environment.config_leads_path}`;
  // private urlConfigLeads = `http://localhost:8081${environment.config_leads_path}`;
  private urlReporting = `${environment.base_url}${environment.reporting_path}`;
  // private urlReporting = `http://localhost:8082${environment.reporting_path}`;

  constructor(private http: HttpClient) {}


  getClients(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_CLIENTS;
    return this.http.post<any>(path, body, { headers });
  };

  getFamilyClients(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = `${this.urlReporting}/client-family/variable-list`;
    return this.http.post<any>(path, body, { headers });
  };

  getHierarchy(body: any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    const path = PathRest.GET_HIERARCHY;
    return this.http.post<HierarchyModel>(path, body, { headers });
  };

}
