import { Component, ViewEncapsulation } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../services/message.service';
import { PrincipalFormService } from 'src/app/pages/principal/services/principal-form.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  hide: boolean = true;
  loginForm: FormGroup;
  public formSubmitted = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private authservice: AuthService,
    private readonly securityService: SecurityService,
    private principalFormService: PrincipalFormService
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(6),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
    });
    
    localStorage.removeItem("access");
  }

  sendData() {
    if (!this.loginForm.invalid) {
      this.authservice.logIn(this.loginForm.value).subscribe((resp: any) => {
        if (resp.mensaje) {
          this.messageService.showInfo(
            JSON.stringify(resp.mensaje),
            'top right'
          );
        } else {
          localStorage.setItem('usuario', JSON.stringify(this.loginForm.value));
          localStorage.removeItem('tokenBO');
          localStorage.setItem('usuarioLogin', JSON.stringify(resp.userinfo));
          const roleId = resp.userinfo?.idRoleMenu;
          this.getMenu(roleId);
        }
      });
    } else {
      this.formSubmitted = true;
    }
  }

  getMenu(roleId: number) {
    this.securityService.getMenu(roleId)
      .subscribe({
        next: (response) => {
          this.securityService.setMenuItems(response || []);
          if (response?.length > 0) {
            localStorage.setItem("access", JSON.stringify(response))
          }
          this.goToDashboard();
        },
        error: () => this.goToDashboard()
      })
  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
    this.principalFormService.resetData();
  }

}
