<div class="container-login ibk-flex-row ibk-flex" fxFlexFill>
  <div class="ibk-flex-row ibk-flex left">
    <div class="image-logo">
      <div class="VP_Logo"></div>
    </div>
  </div>
  <div class="ibk-flex right">
    <div class="right-container container-one">
      <form action="index.html" autocomplete="off">
          
        <div class="input-div one">
        </div>
        <div> </div> 
        <button  type="button" mat-raised-button color="primary" (click)="loginPopup()">Ingresar</button>
      </form>
    </div>

  </div>
</div>
