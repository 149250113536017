import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { HierarchyModel } from 'src/app/models/hierarchy.model';
import { BaseComponent } from 'src/app/shared/core/base/base.component';
import { HierarchyDescEnum, HierarchyEnum } from 'src/app/shared/core/enums/hierarchy.enum';
import { HierarchyListFacade } from 'src/app/store/client/state/hierarchy-list/hierarchy-list.facade';

@Component({
  selector: 'app-hierarchy',
  templateUrl: './hierarchy.component.html',
  styleUrls: ['./hierarchy.component.scss']
})
export class HierarchyComponent extends BaseComponent implements OnInit, OnDestroy  {

  @Input() module: number;
  @Input() flagAnalist:boolean = false;
  @Output() sendFilter = new EventEmitter<string | null>();
  @Output() sectoristas = new EventEmitter<HierarchyModel[]>();
  @Output() firstHierarchiesLoaded = new EventEmitter<HierarchyModel | null>();

  loading: boolean = false;
  filterForm: FormGroup;
  loggedInUserHierarchy?:{ id: number, name: string } | null = null;
  bancaList: HierarchyModel[] = [];
  zonalList: HierarchyModel[] = [];
  jefaturaList: HierarchyModel[] = [];
  ejecutivoList: HierarchyModel[] = [];
  sectoristasList: HierarchyModel[] = [];
  sectorista: boolean = true;
  firstLoad: boolean = true;
  private userSelected: string | null = null;

  hierarchyGroupList: { id: number, name: string }[] = [
    {id: HierarchyEnum.GESTOR_CAMPAÑA, name: HierarchyDescEnum.GESTOR_CAMPAÑA},
    {id: HierarchyEnum.BANCA, name: HierarchyDescEnum.BANCA},
    {id: HierarchyEnum.ZONAL, name: HierarchyDescEnum.ZONAL},
    {id: HierarchyEnum.JEFATURA, name: HierarchyDescEnum.JEFATURA},
    {id: HierarchyEnum.ANALISTA, name: HierarchyDescEnum.ANALISTA},
    {id: HierarchyEnum.EJECUTIVO, name: HierarchyDescEnum.EJECUTIVO}
  ];


  constructor(private readonly hierarchyListFacade: HierarchyListFacade) { 
    super();
  }

  ngOnInit(): void {
    this.userSelected = this.user;
    this.getHierarchy();
    this.createForm();
    this.listenHierarchy();
  }

  override ngOnDestroy(): void {
    this.hierarchyListFacade.reset();
    super.ngOnDestroy();
  }

  get user(): string {
    return localStorage.getItem('userinfo') || '';
  }

  public getHierarchy() {
    const request = {
      userRegistration: this.userSelected,
      module: this.module || 0
    };
    this.hierarchyListFacade.getHierarchy(request);
  }

  listenHierarchy() {
    this.hierarchyListFacade.selectHierarchy()
      .pipe(takeUntil(this.destroy$))
      .subscribe(hierarchy => {
        const { data, loaded, loading } = hierarchy;
        this.loading = loading;
        if (loaded) {
          const subordinateList = data?.subordinateList || [];
          const ejecutivo = this.hierarchyGroupList.find(item => item.id == HierarchyEnum.EJECUTIVO);
          if (data?.hierarchyGroup == ejecutivo?.name && subordinateList.length > 0 && this.sectorista) {
            this.loggedInUserHierarchy = ejecutivo;
            this.sectoristasList = [...subordinateList];
            this.sectoristas.emit(this.sectoristasList);
          } else {
            const hierarchySelected = this.hierarchyGroupList.find(g => g.name == data?.hierarchyGroup);
            if (!this.loggedInUserHierarchy) {
              this.loggedInUserHierarchy = hierarchySelected;
            }
            this.setBankList(subordinateList, hierarchySelected?.id!);
            this.setZonalList(subordinateList, hierarchySelected?.id!);
            this.setJefaturaList(subordinateList, hierarchySelected?.id!);
            this.setEjecutivoList(subordinateList, hierarchySelected?.id!);
          }

          this.sectorista = false;
          this.setHierarchiesLoaded(data);
          this.selectOnlyExecutive();
        }
      });
  }

  setHierarchiesLoaded(data: HierarchyModel | null) {
    if (this.firstLoad) {
      this.firstHierarchiesLoaded.emit(data);
    }
  }

  setBankList(subordinateList: HierarchyModel[], hierarchyPriority: number) {
    const banca = this.hierarchyGroupList.find(item => item.id == HierarchyEnum.BANCA)?.name;
    if (hierarchyPriority <= HierarchyEnum.GESTOR_CAMPAÑA) {
      this.bancaList = [];
      const data = subordinateList.filter(item => item.hierarchyGroup == banca);
      this.bancaList = [...data];
    }
  }

  setZonalList(subordinateList: HierarchyModel[], hierarchyPriority: number) {
    const zonal = this.hierarchyGroupList.find(item => item.id == HierarchyEnum.ZONAL)?.name;
    if (hierarchyPriority <= HierarchyEnum.BANCA) {
      this.zonalList = [];
      const data = subordinateList.filter(item => item.hierarchyGroup == zonal);
      this.zonalList = [...data];
    }
  }

  setJefaturaList(subordinateList: HierarchyModel[], hierarchyPriority: number) {
    const jefatura = this.hierarchyGroupList.find(item => item.id == HierarchyEnum.JEFATURA)?.name;
    if (hierarchyPriority <= HierarchyEnum.ZONAL) {
      this.jefaturaList = [];
      const data = subordinateList.filter(item => item.hierarchyGroup == jefatura);
      this.jefaturaList = [...data];
    }
  }

  setEjecutivoList(subordinateList: HierarchyModel[], hierarchyPriority: number) {
    const ejecutivo = this.hierarchyGroupList.find(item => item.id == HierarchyEnum.EJECUTIVO)?.name;
    if (hierarchyPriority <= HierarchyEnum.JEFATURA) {
      this.ejecutivoList = [];
      const data = subordinateList.filter(item => item.hierarchyGroup == ejecutivo);
      this.ejecutivoList = [...data];
    }
  }

  createForm() {
    this.filterForm = new FormGroup({
      banca: new FormControl(),
      zonal: new FormControl(),
      jefatura: new FormControl(),
      ejecutivo: new FormControl()
    });
  }

  public selectUser(hierarchy: HierarchyModel, reload: boolean = true) {
    this.userSelected = hierarchy.userRegistration;
    if (reload) {
      this.getHierarchy();
    }
  }

  // selects the first executive, when the analyst has only one executive
  selectOnlyExecutive() {
    if (this.firstLoad && this.flagAnalist && this.ejecutivoList.length == 1){
      let executive = this.ejecutivoList[0];
      this.filterForm.controls['ejecutivo'].setValue(executive);
      this.userSelected = this.filterForm.value.ejecutivo.userRegistration;
    }
    this.firstLoad = false;
  }

  ok() {
    this.sendFilter.emit(this.userSelected);
  }

  cancel() {
    this.createForm();
    this.userSelected = this.user;
    this.getHierarchy();
  }

}
