import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../services/message.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_DATE_FORMATS } from '../../../assets/ts/datepickerConf';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, BrowserUtils, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';

const moment = _rollupMoment || _moment;
@Component({
  selector: 'app-back-office',
  templateUrl: './backoffice.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./backoffice.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MY_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})

export class BackOfficeComponent implements OnInit {
  // date = new FormControl(moment([2017, 0, 1]));

  
  public formSubmitted = false;

  constructor(

    private messageService: MessageService,
    private router: Router,
    private fb: FormBuilder,
    private authservice: AuthService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {

  }

  hide = true;
  ngOnInit() {
  }
  

  loginPopup() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

}
