<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="TypesMenuListEnum.TEXT">
        <div class="menu-list__text" [matMenuTriggerFor]="menuImpact">
            <label class="margin-right-10">{{ labelText }}</label>
            <img [src]="image" alt=">">
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="TypesMenuListEnum.SELECT">
        <div class="menu-list__select width-{{width}}" [matMenuTriggerFor]="menuImpact">
            <span>{{selectText}}</span>
            <img src="/assets/img/arrow-down.svg" alt="arrow">
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <img class="menu-list__img"
            [src]="image" alt=">" 
            [matMenuTriggerFor]="menuImpact"
        >
    </ng-container>
</ng-container>

<mat-menu #menuImpact
    class="margin-top-{{marginTop}} padding-left-15 padding-right-15 padding-top-5 padding-bottom-5 color-text-color-default f-size-13 width-{{width}}">
    <ng-container *ngIf="multiple else sigleList">
        <div>
            <mat-checkbox
                [checked]="allComplete()"
                [indeterminate]="someComplete()"
                (click)="$event.stopPropagation()"
                (change)="setAll($event.checked)"
            >
            Todos
            </mat-checkbox>
        </div>
        <div *ngFor="let form of formArray.controls; let i = index">
            <ng-container [formGroup]="form">
                <mat-checkbox formControlName="checked"
                    (click)="$event.stopPropagation()"
                    (change)="changeValue(form)">
                    {{form.value?.description}}
                </mat-checkbox>
            </ng-container>
        </div>
        <div class="menu-list__button">
            <button mat-button color="primary" 
                (click)="send()">
                {{buttonText}}
            </button>
        </div>
    </ng-container>

    <ng-template #sigleList>
        <div class="menu-list__item" 
            [ngClass]="{'color-primary': !selectedIndex}"
            (click)="selectItem(null)">
            Todos
        </div>
        <div class="menu-list__item"
            [ngClass]="{'color-primary': selectedIndex == i}"
            *ngFor="let form of formArray.getRawValue(); let i = index"
            (click)="selectItem(i, form)">
            {{form.description}}
        </div>

    </ng-template>

</mat-menu>
