import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginForm } from 'src/app/interfaces/login-form.interface';
import { catchError } from 'rxjs/operators'; import { map, tap } from 'rxjs/operators'
import { Router } from '@angular/router';
import { PathRest } from 'src/app/static/path.rest';
import { Observable, of } from 'rxjs';
import { Ilogin } from '../models/login.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private router: Router) {

  }

  logIn(formData: LoginForm) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'OriginHostName': 'IBK-LIMA',
      'OriginIp': '192.168.1.11',
      'channel': 'Postman',
      'clientId': environment.authAD.clientId,
      'clientSecret': environment.authAD.clientSecret,
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'access-control-allow-private-network' : 'true'
    })

    return this.http.post<Ilogin>(PathRest.GET_LOGIN, formData, { headers })
      .pipe(
        tap(resp => {
          if(resp.userinfo && resp.access_token){
            localStorage.setItem('access_token', resp.access_token);
          localStorage.setItem('userinfo', resp.userinfo.username);
          }
        })
      )
  }

  get token(): string {
    return localStorage.getItem('access_token') || '';
  }
  get user(): string {
    return localStorage.getItem('userinfo') || '';
  }

  get userType(): Array<string> {
    let typesList:Array<string> = new Array();
    let userLogin = localStorage.getItem("usuarioLogin");
    if (userLogin && userLogin != null ) {
      let userLoginObject = JSON.parse(userLogin);
      if(userLoginObject.groups != undefined){

        let groupsUserList = userLoginObject.groups
        const contentAnalist = groupsUserList.some((group:string) => group.includes("ANALISTA"));
        if(contentAnalist) typesList.push('Analista');
        const contentEjecutive = groupsUserList.some((group:string) => group.includes("EJECU"));
        if(contentEjecutive) typesList.push('Ejecutivo');
        const contentAdmin = groupsUserList.some((group:string) => group.includes("ADMINISTRADOR"));
        if(contentAdmin) typesList.push('Administrador');
        const contentGestorC = groupsUserList.some((group:string) => group.includes("GESTOR_CAMPA"));
        if(contentGestorC) typesList.push('Gestor Campaña');

        return typesList;
      }
    }
    return typesList;
  }

  logOut() {

    const headers = new HttpHeaders({
      'OAuth-Token': this.token,
      'clientId': environment.authAD.clientId,
      'clientSecret': environment.authAD.clientSecret,
      'OriginIp': '192.168.1.11',
      'OriginHostName': 'IBK-LIMA',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'channel': 'web',
      'Content-Type': 'application/json',
      'access-control-allow-private-network' : 'true'
    })
    let params = new HttpParams().append('username', this.user);
    localStorage.removeItem('access_token');
    localStorage.removeItem('userinfo');
    localStorage.removeItem('access');
    this.router.navigateByUrl('/login');
    return this.http.delete(PathRest.GET_LOGOUT, { params, headers });
  };

  validarToken(): Observable<boolean> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + window.btoa(environment.authAD.clientId + ":" + environment.authAD.clientSecret),
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'access-control-allow-private-network' : 'true'
    })
    let params = new HttpParams().append('token', this.token);
    return this.http.get(PathRest.GET_CHECK_TOKEN, { headers, params }).pipe(
      map(resp => true),
      catchError( error => of(false))
    )

  }

  generarTokenBO(user:string, pass: string){
    const body = {
      "password": pass,
      "authType": "secWinAD",
      "username": user+"@GRUPOIB.LOCAL"
    };

    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'userRegistration':localStorage.getItem('userinfo') || ''
    };
    return this.http.post(PathRest.POST_TOKEN_BO,body,{headers});
  }

  getArbol(token:string){
    const headers = {
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey,
      'userRegistration':localStorage.getItem('userinfo') || ''
    };
    const body = {
      "token": token
    };
    console.log("request Arbol: ", body);
    return this.http.post(PathRest.POST_ARBOL_DIARIO, body, { headers, responseType: "text" });
  }

  getTokenMS(){
    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    let body = new FormData();
    body.append("grant_type","client_credentials");
    body.append("client_secret","IH88Q~ZfW-hyqyzXu3gsssFm9-0Pefrzpv2ZwbW~");
    body.append("client_id","722264fa-177e-4544-be36-5cb59c139e55");
    return this.http.post("https://login.microsoftonline.com/05b0d9b6-aa36-47ec-9050-3a8484df3072/oauth2/token", body, { headers });
  }

  getTokenPBI(token: any, body: any){
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer '+token
    };
    return this.http.post("https://api.powerbi.com/v1.0/myorg/GenerateToken", body, { headers });
  }

  getAccessToken(id:any) {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Ocp-Apim-Subscription-Key': environment.ocpApimSubscriptionKey
    };
    return this.http.get(`${PathRest.GET_EMBED_FICHA_CLIENTE}?report=${id}`, {headers});
  }
}
